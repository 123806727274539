<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8" :style="sitePageTheme.header">
    </base-header>

    <div class="container-fluid mt--7" :style="sitePageTheme.body">
      <div class="row">
        <div class="col">
          <div class="card mb-3">
            <div class="row no-gutters">
              <div class="col-md-12">
                <div
                  class="card shadow"
                  :class="type === 'dark' ? 'bg-default' : ''"
                >
                  <div
                    class="card-header border-0"
                    :class="type === 'dark' ? 'bg-transparent' : ''"
                  >
                    <div class="row align-items-center">
                      <div class="col-md-3">
                        <h3
                          class="mb-0"
                          :class="type === 'dark' ? 'text-white' : ''"
                        >
                          Your Profile
                        </h3>
                      </div>
                      <div
                        class="col-md-9 text-right"
                        v-if="clpSitePageSettings.allow_self_update"
                      >
                        <div>
                          <a-popconfirm
                            placement="bottom"
                            ok-text="Yes"
                            cancel-text="No"
                            v-if="
                              requestOtpForUpdatingEmail ||
                                requestOtpForUpdatingMobile
                            "
                            @confirm="
                              () => {
                                $v.formData.$touch();

                                if (!$v.formData.$invalid) {
                                  if (requestOtpForUpdatingEmail) {
                                    requestOtpForEmail();
                                    startSmsTimer();
                                  }
                                  if (requestOtpForUpdatingMobile) {
                                    requestOtpForMobile();
                                    startEmailTimer();
                                  }
                                }
                              }
                            "
                          >
                            <template slot="title">
                              <p>
                                Are you sure you want to update you profile?
                              </p>
                            </template>
                            <base-button
                              id="new"
                              class="float-right ml-1"
                              type="primary"
                              size="sm"
                              :disabled="formSubmitting"
                              ><span v-if="!formSubmitting"
                                >Update Profile</span
                              >
                              <span v-else
                                ><b-spinner small></b-spinner> Updating
                                ...</span
                              ></base-button
                            >
                          </a-popconfirm>

                          <a-popconfirm
                            v-else
                            placement="bottom"
                            ok-text="Yes"
                            cancel-text="No"
                            @confirm="updateProfile"
                          >
                            <template slot="title">
                              <p>
                                Are you sure you want to update you profile?
                              </p>
                            </template>
                            <base-button
                              id="new"
                              class="float-right ml-1"
                              type="primary"
                              size="sm"
                              :disabled="formSubmitting"
                              ><span v-if="!formSubmitting"
                                >Update Profile</span
                              >
                              <span v-else
                                ><b-spinner small></b-spinner> Updating
                                ...</span
                              ></base-button
                            >
                          </a-popconfirm>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <form role="form" style="padding: 1.5rem;">
                      <h6 class="heading-small text-muted text-truncate">
                        <span>Personal Info</span>
                      </h6>
                      <div class="row">
                        <div
                          class="col-4"
                          v-if="fieldSettings && fieldSettings.first_name && fieldSettings.first_name.is_visible"
                        >
                          <base-input label="First Name" required>
                            <a-input
                              v-model.trim="formData.first_name"
                              :disabled="
                                !fieldSettings.first_name.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            />
                            <div
                              v-if="$v.formData.first_name && $v.formData.first_name.$dirty"
                              class="text-danger"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.first_name.required"
                              >
                                Field is required
                              </div>
                            </div>
                          </base-input>
                        </div>
                        <div
                          class="col-3"
                          v-if="fieldSettings &&  fieldSettings.middle_name.is_visible"
                        >
                          <base-input label="Middle Name">
                            <a-input
                              v-model.trim="formData.middle_name"
                              :disabled="
                                !fieldSettings.middle_name.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            />
                          </base-input>
                        </div>

                        <div
                          class="col-4"
                          v-if="fieldSettings &&  fieldSettings.last_name.is_visible"
                        >
                          <base-input label="Last Name" required>
                            <a-input
                              v-model.trim="formData.last_name"
                              :disabled="
                                !fieldSettings.last_name.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            />
                            <div
                              v-if="$v.formData.last_name && $v.formData.last_name.$dirty"
                              class="text-danger"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.last_name.required"
                              >
                                Field is required
                              </div>
                            </div>
                          </base-input>
                        </div>

                        <div class="col-1">
                          <base-input
                            label="Suffix"
                            v-if="fieldSettings && fieldSettings.last_name_suffix && fieldSettings.last_name_suffix.is_visible"
                          >
                            <a-input
                              v-model.trim="formData.last_name_suffix"
                              :disabled="
                                !fieldSettings.last_name_suffix.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            />
                          </base-input>
                        </div>
                      </div>

                      <div class="row">
                        <div
                          class="col-5"
                          v-if="fieldSettings &&
                            fieldSettings.birth_month.is_visible &&
                              fieldSettings.birth_day.is_visible &&
                              fieldSettings.birth_year.is_visible
                          "
                        >
                          <base-input label="Birthday">
                            <div class="row">
                              <div
                                class="col-4"
                                v-if="fieldSettings.birth_month.is_visible"
                              >
                                <a-select
                                  v-model="birthMonth"
                                  :disabled="
                                    !fieldSettings.birth_month.is_editable ||
                                      !clpSitePageSettings.allow_self_update
                                  "
                                >
                                  <a-select-option
                                    v-for="month in options.months"
                                    :key="month.key"
                                    :value="month.key"
                                  >
                                    {{ month.name }}
                                  </a-select-option>
                                </a-select>
                                <small class="text-muted">
                                  <i>Month</i>
                                  <span class="text-danger" v-if="isMandatory('birth_month')">*</span>
                                  <br>
                                  <div v-if="isMandatory('birth_month')">
                                    <div v-if="$v.birthMonth && $v.birthMonth.$dirty" class="text-danger">
                                      <div class="error" v-if="!$v.birthMonth.required">
                                        Field is required
                                      </div>
                                    </div>
                                  </div>
                                </small>
                              </div>
                              /
                              <div
                                class="col-3"
                                v-if="fieldSettings.birth_day.is_visible"
                              >
                                <a-select
                                  v-model="birthDay"
                                  :disabled="
                                    !fieldSettings.birth_day.is_editable ||
                                      !clpSitePageSettings.allow_self_update
                                  "
                                >
                                  <a-select-option
                                    v-for="day in 31"
                                    :key="zeroPad(day, 2)"
                                    :value="zeroPad(day, 2)"
                                  >
                                    {{ zeroPad(day, 2) }}
                                  </a-select-option>
                                </a-select>
                                <small class="text-muted">
                                  <i>Day</i>
                                  <span class="text-danger" v-if="isMandatory('birth_day')">*</span>
                                  <br>
                                  <div v-if="isMandatory('birth_day')">
                                    <div v-if="$v.birthDay &&  $v.birthDay.$dirty" class="text-danger">
                                      <div class="error" v-if="!$v.birthDay.required">
                                        Field is required
                                      </div>
                                    </div>
                                  </div>
                                </small>
                              </div>
                              /
                              <div
                                class="col-4"
                                v-if="fieldSettings.birth_year.is_visible"
                              >
                                <a-select
                                  v-model="birthYear"
                                  :disabled="
                                    !fieldSettings.birth_year.is_editable ||
                                      !clpSitePageSettings.allow_self_update
                                  "
                                >
                                  <a-select-option
                                    v-for="year in 101"
                                    :key="parseInt(yearStart) + parseInt(year)"
                                    :value="
                                      parseInt(yearStart) + parseInt(year)
                                    "
                                  >
                                    {{ parseInt(yearStart) + parseInt(year) }}
                                  </a-select-option>
                                </a-select>
                                <small class="text-muted">
                                  <i>Year</i>
                                  <span class="text-danger" v-if="isMandatory('birth_year')">*</span>
                                  <br>
                                  <div v-if="isMandatory('birth_year')">
                                    <div v-if="$v.birthYear && $v.birthYear.$dirty" class="text-danger">
                                      <div class="error" v-if="!$v.birthYear.required">
                                        Field is required
                                      </div>
                                    </div>
                                  </div>
                                </small>
                              </div>
                            </div>
                          </base-input>
                        </div>

                        <div
                          class="col-4"
                          v-if="fieldSettings &&  fieldSettings.gender &&  fieldSettings.gender.is_visible"
                        >
                          <base-input label="Gender" :required="isMandatory('gender')">
                            <br />
                            <a-select
                              v-model="formData.gender"
                              :disabled="
                                !fieldSettings.gender.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            >
                              <a-select-option
                                v-for="gender in options.genders"
                                :key="gender.key"
                                :value="gender.key"
                              >
                                {{ gender.name }}
                              </a-select-option>
                            </a-select>
                            <div v-if="isMandatory('gender')">
                              <div v-if="$v.formData.gender &&  $v.formData.gender.$dirty" class="text-danger">
                                <div class="error" v-if="!$v.formData.gender.required">
                                  Field is required
                                </div>
                              </div>
                            </div>
                          </base-input>
                        </div>

                        <div
                          class="col-3"
                          v-if="fieldSettings && fieldSettings.nick_name && fieldSettings.nick_name.is_visible"
                        >
                          <base-input label="Nickname">
                            <a-input
                              v-model.trim="formData.nick_name"
                              :disabled="
                                !fieldSettings.nick_name.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            />
                          </base-input>
                        </div>
                      </div>
                      <hr />
                      <h6 class="heading-small text-muted text-truncate">
                        <span>Address</span>
                      </h6>
                      <div class="row">
                        <div
                            class="col"
                            v-if="fieldSettings && fieldSettings.location_region &&  fieldSettings.location_region.is_visible"
                        >
                          <base-input label="Region" :required="isMandatory('location_region')">
                            <br />
                            <a-select
                                v-model="formData.region_code"
                                @change="onChangeRegion"
                                :disabled="
                                !fieldSettings.location_region.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            >
                              <a-select-option
                                  v-for="region in options.regions"
                                  :key="region.code"
                                  :value="region.code"
                              >
                                {{ region.regionName }} - {{ region.name }}
                              </a-select-option>
                            </a-select>
                            <div v-if="isMandatory('location_region')">
                              <div v-if="$v.formData.region_code && $v.formData.region_code.$dirty" class="text-danger">
                                <div class="error" v-if="!$v.formData.region_code.required">
                                  Field is required
                                </div>
                              </div>
                            </div>
                          </base-input>
                        </div>
                        <div
                            class="col"
                            v-if="fieldSettings && fieldSettings.location_province &&
                            fieldSettings.location_province.is_visible &&
                              !isRegionNCR
                          "
                        >
                          <base-input label="Province">
                            <br />
                            <a-select
                                v-model="formData.province_code"
                                @change="onChangeProvince"
                                :disabled="
                                !fieldSettings.location_province.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            >
                              <a-select-option
                                  v-for="province in options.provinces"
                                  :key="province.code"
                                  :value="province.code"
                              >
                                {{ province.name }}
                              </a-select-option>
                            </a-select>
                          </base-input>
                        </div>
                        <div
                            class="col"
                            v-if="fieldSettings && fieldSettings.location_city && fieldSettings.location_city.is_visible"
                        >
                          <base-input label="City/Municipality" :required="isMandatory('location_city')">
                            <br />
                            <a-select
                                v-model="municity"
                                @change="onChangeCity"
                                :disabled="
                                !fieldSettings.location_city.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            >
                              <a-select-option
                                  v-for="city in options.cities"
                                  :key="city.code"
                                  :value="city.code"
                                  :data-city="String(city.isCity)"
                              >
                                {{ city.name }}
                              </a-select-option>
                            </a-select>
                            <div v-if="isMandatory('location_city')">
                              <div v-if="$v.municity && $v.municity.$dirty" class="text-danger">
                                <div class="error" v-if="!$v.municity.required">
                                  Field is required
                                </div>
                              </div>
                            </div>
                          </base-input>
                        </div>
                      </div>
                      <div class="row">
                        <div
                            class="col"
                            v-if="fieldSettings && fieldSettings.location_barangay && fieldSettings.location_barangay.is_visible"
                        >
                          <base-input label="Barangay">
                            <br />
                            <a-select
                                v-model="formData.barangay_code"
                                @change="onChangeBarangay"
                                :disabled="
                                !fieldSettings.location_barangay.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            >
                              <a-select-option
                                  v-for="barangay in options.barangays"
                                  :key="barangay.code"
                                  :value="barangay.code"
                              >
                                {{ barangay.name }}
                              </a-select-option>
                            </a-select>
                          </base-input>
                        </div>
                        <div
                            class="col"
                            v-if="fieldSettings && fieldSettings.zipcode && fieldSettings.zipcode.is_visible"
                        >
                          <base-input label="Zipcode">
                            <a-input
                                v-model.trim="formData.zipcode"
                                :disabled="
                                !fieldSettings.zipcode.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            />
                          </base-input>
                          <div v-if="isZipcodeError">
                            <div class="text-danger">
                              <div class="error">Field only accepts number</div>
                            </div>
                          </div>
                          <div v-if="isMandatory('zipcode')">
                            <div v-if="$v.formData.zipcode && $v.formData.zipcode.$dirty" class="text-danger">
                              <div class="error" v-if="!$v.formData.zipcode.required">
                                Field is required
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="row"
                        v-if="fieldSettings && fieldSettings.location_unit &&
                          fieldSettings.location_unit.is_visible &&
                            fieldSettings.location_street.is_visible
                        "
                      >
                        <div
                          class="col-3"
                          v-if="fieldSettings.location_unit.is_visible"
                        >
                          <base-input label="Unit No.">
                            <a-input
                              v-model.trim="formData.location_unit"
                              :disabled="
                                !fieldSettings.location_unit.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            />
                          </base-input>
                        </div>
                        <div
                          class="col-9"
                          v-if="fieldSettings && fieldSettings.location_street &&   fieldSettings.location_street.is_visible"
                        >
                          <base-input label="Street">
                            <a-input
                              v-model.trim="formData.location_street"
                              :disabled="
                                !fieldSettings.location_street.is_editable ||
                                  !clpSitePageSettings.allow_self_update
                              "
                            />
                          </base-input>
                        </div>
                      </div>
                      <hr />
                      <h6 class="heading-small text-muted text-truncate">
                        <span>Contact Details</span>
                      </h6>
                      <div class="row">
                        <div
                          class="col-6"
                          v-if="fieldSettings && fieldSettings.mobile_no &&  fieldSettings.mobile_no.is_visible"
                        >
                          <base-input label="Mobile Number" :required="isMandatory('mobile_no')">
                            <!-- <a-input
                              v-model.trim="formData.mobile_no"
                              :disabled="
                                !fieldSettings.mobile_no.is_editable ||
                                  !clpSitePageSettings.allow_self_update ||
                                  !canUpdateMobile
                              "
                            /> -->

                            <a-input-group compact>
                              <a-select
                                default-value="+63"
                                style="width: 15%"
                                v-model="countryCode"
                                :disabled="
                                  !fieldSettings.mobile_no.is_editable ||
                                    !clpSitePageSettings.allow_self_update ||
                                    !canUpdateMobile
                                "
                              >
                                <a-select-option value="+63">
                                  +63
                                </a-select-option>
                              </a-select>
                              <a-input
                                v-model="mobileNo"
                                style="width: 85%"
                                placeholder="9XXXXXXXXX"
                                :disabled="
                                  !fieldSettings.mobile_no.is_editable ||
                                    !clpSitePageSettings.allow_self_update ||
                                    !canUpdateMobile
                                "
                              />
                            </a-input-group>
                            <div
                              v-if="$v.formData.mobile_no && $v.formData.mobile_no.$dirty"
                              class="text-danger"
                            >
                              <div
                                class="error"
                                v-if="!$v.formData.mobile_no.required"
                              >
                                Field is required
                              </div>
                              <div
                                class="error"
                                v-else-if="
                                  !$v.formData.mobile_no.mobileNoFormat
                                "
                              >
                                Invalid mobile number format. Use +639XXXXXXXXX
                                format.
                              </div>
                            </div>
                          </base-input>
                        </div>
                        <div
                          class="col-6"
                          v-if="fieldSettings && fieldSettings.email &&  fieldSettings.email.is_visible"
                        >
                          <base-input label="Email Address">
                            <a-input
                              v-model.trim="formData.email"
                              :disabled="
                                !fieldSettings.email.is_editable ||
                                  !clpSitePageSettings.allow_self_update ||
                                  !canUpdateEmail
                              "
                            />
                          </base-input>
                        </div>
                      </div>
                      <hr  v-if="dynamic_form.length > 0"/>
                      <h6  v-if="dynamic_form.length > 0" class="heading-small text-muted text-truncate">
                        <span>Other Info</span>
                      </h6>
                      <div  v-if="dynamic_form.length > 0" class="row">
                        <dynamic-form
                            ref="dynamicForm"
                            :convertedJson="{ mode: 'tabs', groups: dynamic_form }"
                            :disabled="viewMode"
                            :formSettings="memberDataMeta"
                            @valueUpdated="updateSettings"
                            :key="dynamicFormKey"
                            :formData="formData"
                            :membershipPrograms="options.membershipPrograms"
                            :membershipLevels="options.membershipLevels"
                        ></dynamic-form>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      v-model="otpRequestModal"
      title="OTP Verification Form"
      @ok="
        () => {
          updateProfile();
        }
      "
      @cancel="resetOtpTimers"
    >
      <div v-if="requestOtpForUpdatingEmail">
        <p>Please enter the OTP sent to your <b>Mobile Number</b></p>
        <small
          >This authentication is to verify that you are updating your
          email.</small
        >
        <!-- <a-input
          v-model="otpCode"
          placeholder="Enter received OTP from mobile number"
        /> -->

        <a-input-search
          placeholder="Enter received OTP from mobile number"
          v-model="otpCode"
          @search="resendOtpForEmail"
        >
          <a-button
            slot="enterButton"
            :disabled="!canResendSmsOtp"
            v-if="resendSmsTimer > 0"
          >
            Resend in {{ resendSmsTimer }}(s)
          </a-button>
          <a-button
            slot="enterButton"
            :disabled="!canResendSmsOtp || resendingSmsOtp"
            v-else
          >
            Resend OTP Request
          </a-button>
        </a-input-search>
      </div>

      <div v-if="requestOtpForUpdatingMobile">
        <p>Please enter the OTP sent to your <b>Email</b></p>
        <small
          >This authentication is to verify that you are updating your mbile
          number.</small
        >
        <!-- <a-input
          v-model="otpEmailCode"
          placeholder="Enter received OTP from email"
        /> -->

        <a-input-search
          placeholder="Enter received OTP from email"
          v-model="otpEmailCode"
          @search="resendOtpForMobile"
        >
          <a-button
            slot="enterButton"
            :disabled="!canResendEmailOtp"
            v-if="resendEmailTimer > 0"
          >
            Resend in {{ resendEmailTimer }}(s)
          </a-button>
          <a-button
            slot="enterButton"
            :disabled="!canResendEmailOtp || resendingEmailOtp"
            v-else
          >
            Resend OTP Request
          </a-button>
        </a-input-search>
      </div>
    </a-modal>
  </div>
</template>

<script>
import PSGCApi from '../../controllers/psgcApi';
import DynamicForm from '../DynamicForm.vue';
import HTTPRequest from '../../controllers/httpRequest';
import moment from 'moment';
import secureStorage from '../../controllers/secureStorage';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'MemberProfile',
  props: {
    viewMode: Boolean,
    disableMemberProfileTab: Boolean,
    type: String,
    clpSitePageSettings: Object,
    sitePageTheme: Object
  },
  components: {
    DynamicForm
  },
  data() {
    return {
      mandatoryFields: null,
      countryCode: '+63',
      mobileNo: null,

      memberData: null,
      isZipcodeError: false,
      otpRequestModal: false,
      // gradientType: 'gradient-green',
      requestOtpForUpdatingMobile: false,
      requestOtpForUpdatingEmail: false,
      showOtpModal: false,

      canResendSmsOtp: false,
      resendingSmsOtp: false,
      resendSmsTimer: 60, // in seconds
      smsTimer: null,
      dynamic_form:[],
      memberDataMeta: {},
      dynamicFormKey: 0,
      canResendEmailOtp: false,
      resendingEmailOtp: false,
      resendEmailTimer: 60, // in seconds
      emailTimer: null,

      otp: {},
      otpEmail: {},
      otpEmailCode: null,
      otpCode: null,
      municity: null,
      formSubmitting: false,
      hasCustomFieldError: false,
      formData: {
        membership_program_id: null,
        membershipProgram: null,
        membership_level_id: null,
        first_name: '',
        middle_name: '',
        last_name: '',
        last_name_suffix: '',
        nick_name: '',
        allow_null_mobile_no: null,
        mobile_no: '',
        email: '',
        zipcode:null,
        location_unit: '',
        location_street: '',
        location_barangay: '',
        barangay_code: null,
        location_city: '',
        city_code: null,
        location_municipality: '',
        municipality_code: null,
        location_province: '',
        province_code: null,
        location_region: null,
        region_code: null,
        location_country: 'PH',
        gender: null,
        meta: null,
        birth_month: null,
        birth_year: null,
        birth_day: null,
        dpc_consent: false
      },

      fieldSettings: null,
      options: {
        barangays: [],
        cities: [],
        provinces: [],
        regions: [],
        membershipPrograms: [],
        membershipLevels: [],
        citiesOrMunicipalities: [],
        genders: [
          { key: 'M', name: 'Male' },
          { key: 'F', name: 'Female' }
        ],
        months: [
          { key: '01', name: 'January' },
          { key: '02', name: 'Febuary' },
          { key: '03', name: 'March ' },
          { key: '04', name: 'April ' },
          { key: '05', name: 'May ' },
          { key: '06', name: 'June' },
          { key: '07', name: 'July ' },
          { key: '08', name: 'August ' },
          { key: '09', name: 'September' },
          { key: '10', name: 'October' },
          { key: '11', name: 'November' },
          { key: '12', name: 'December' }
        ]
      },
      birthMonth: null,
      birthYear: null,
      birthDay: null,
      yearStart: moment().year() - 101,
      isRegionNCR: false,
      otpGenerated: false,
      otpEmailGenerated: false,
      canUpdateEmail: true,
      canUpdateMobile: true
    };
  },

  validations() {
    let validation = {
      formData: {
        first_name: { required },
        last_name: { required }
      }
    };

    if(this.mandatoryFields != null) {
      if(this.mandatoryFields.email == true) {
        validation.formData['email'] = { required, email };
      }
  
      if(this.mandatoryFields.gender == true) {
        validation.formData['gender'] = { required };
      }
  
      if(this.mandatoryFields.location_city == true) {
        validation['municity'] = { required };
      }
  
      if(this.mandatoryFields.location_region == true) {
        validation.formData['region_code'] = { required };
      }
  
      if(this.mandatoryFields.birth_month == true) {
        validation['birthMonth'] = { required };
      }
  
      if(this.mandatoryFields.birth_day == true) {
        validation['birthDay'] = { required };
      }

      if(this.mandatoryFields.birth_year == true) {
        validation['birthYear'] = { required };
      }

      if(this.mandatoryFields.zipcode == true) {
        validation['zipcode'] = {
          required,
          numeric: (value) => /^\d+$/.test(value)
        };
      }

      if(this.mandatoryFields.mobile_no == true) {
        if(this.mandatoryFields.allow_null_mobile_no == true && this.formData.allow_null_mobile_no == true) {
          validation.formData['mobile_no'] = { 
            mobileNoFormat(value) {
              const regex = /^(\+639)\d{9}$/;
              return regex.test(value);
            }
          };
        } else {
          validation.formData['mobile_no'] = {
            required,
            mobileNoFormat(value) {
              const regex = /^(\+639)\d{9}$/;
              return regex.test(value);
            }
          }
        }
      }
    } else {
      validation.formData['mobile_no'] = {
        required,
        mobileNoFormat(value) {
          const regex = /^(\+639)\d{9}$/;
          return regex.test(value);
        }
      }
    }

    return validation;
  },

  methods: {
    moment,

    updateSettings(newFormSettings, settingsGrouping, errors) {
      this.formData.meta = newFormSettings;

      if (errors.length > 0) {
        this.hasCustomFieldError = true;
      } else {
        this.hasCustomFieldError = false;
      }
    },
    isMandatory(key) {
      if(this.mandatoryFields != null) {
        if(key == 'mobile_no') {
          if(this.mandatoryFields['allow_null_mobile_no']) {
            return false
          }
        } else if(!this.mandatoryFields[key]) {
          return false;
        }
      }
      return true;
    },
    async getRegions() {
      this.options.regions = await PSGCApi.getRegions();
    },
    async getDynamicFields() {
      const response = await HTTPRequest.getRequest(
          '/api/v2/clp_account_dynamic_customer_fields'
      );
      if (!response.error) {
        const data = JSON.parse(response.dynamic_form);
        data.forEach(obj => {
          obj.terminalSettings = obj.terminalSettings.filter(setting => setting.publishToMobileAndWeb == true) ;
        });

        this.dynamic_form = data.filter(obj => {
          const settings = obj.terminalSettings.filter(setting => setting.publishToMobileAndWeb == true);
          return settings.length > 0;
        });
        let meta = [];
        if(this.clpSitePageSettings.custom_field_settings ) {
          meta = JSON.parse(this.clpSitePageSettings.custom_field_settings);
        }

        this.dynamic_form.forEach((newObject) => {
          Object.keys(meta).forEach((key) => {
            const index = meta[key].findIndex((obj) => {
              return obj.group_key === newObject.key;
            });
            if (index !== -1) {
              newObject.terminalSettings.forEach((newObj) => {
                const matchedOldObj = meta[key].findIndex(
                    (oldObj) => oldObj.key === newObj.key
                );
                if (matchedOldObj !== -1) {
                  newObj.disabled = meta[key][matchedOldObj].is_editable != true
                  newObj.required =  meta[key][matchedOldObj].is_required
                  newObj.is_visible  =  meta[key][matchedOldObj].is_visible
                }
              });
            }
          });
        });
        this.dynamic_form.forEach(obj => {
          obj.terminalSettings = obj.terminalSettings.filter(setting => setting.is_visible == true) ;
        });

        this.dynamic_form = this.dynamic_form.filter(obj => {
          const settings = obj.terminalSettings.filter(setting => setting.is_visible == true);
          return settings.length > 0;
        });
        this.viewMode = false;
        console.log('dynamic_form',this.dynamic_form)
      }
      this.dynamicFormKey++;
    },

    async getMembershipPrograms() {
      const response = await HTTPRequest.getRequest(
          '/api/v2/clp_membership_programs'
      );
      this.getMembershipLevels(response.data[0].id)
      this.options.membershipPrograms = response.data.sort((a, b) =>
          a.name.localeCompare(b.name)
      );
    },

    async getMembershipLevels(membershipProgramId) {
      const data = await HTTPRequest.getRequest(
          `/api/v2/clp_membership_programs/${membershipProgramId}/membership_levels`
      );
      this.options.membershipLevels = data.sort((a, b) =>
          a.name.localeCompare(b.name)
      );
    },

    async getProvinces(regionCode) {
      this.isRegionNCR = regionCode == PSGCApi.NCR_REGION_CODE ? true : false;

      if (this.isRegionNCR) {
        this.options.cities = await PSGCApi.getProvinces(regionCode);
      } else {
        this.options.provinces = await PSGCApi.getProvinces(regionCode);
      }
    },

    async getCities(provinceCode) {
      this.options.cities = await PSGCApi.getCitiesOrMunicipalities(provinceCode);
    },

    async getBarangays(cityCode) {
      this.options.barangays = await PSGCApi.getBarangays(cityCode);
    },

    onChangeRegion() {
      this.formData.location_region = event.target.innerText;
      this.formData.province_code = null;
      this.formData.location_province = null;
      this.options.provinces = [];
      this.formData.city_code = null;
      this.formData.location_city = null;
      this.formData.location_municipality = null;
      this.formData.municipality_code = null;
      this.municity = null;
      this.options.cities = [];
      this.formData.barangay_code = null;
      this.formData.location_barangay = null;
      this.options.barangays = [];
      this.getProvinces(this.formData.region_code);
    },

    onChangeProvince() {
      this.formData.location_province = event.target.innerText;
      this.formData.city_code = null;
      this.formData.location_city = null;
      this.options.cities = [];
      this.formData.barangay_code = null;
      this.formData.location_barangay = null;
      this.options.barangays = [];
      this.getCities(this.formData.province_code);
    },

    onChangeCity() {
      const isCity = event.target.dataset.city;
      this.municity = event.target.__vue__.value;
      if (isCity == true) {
        this.formData.location_city = event.target.innerText;
        this.formData.city_code = this.municity;
      } else {
        this.formData.location_municipality = event.target.innerText;
        this.formData.municipality_code = this.municity;
      }

      this.formData.barangay_code = null;
      this.formData.location_barangay = null;
      this.options.barangays = [];
      this.getBarangays(this.municity);
    },

    onChangeBarangay() {
      this.formData.location_barangay = event.target.innerText;
    },

    zeroPad(num, places) {
      return String(num).padStart(places, '0');
    },

    async updateProfile() {
      let nonFormDataValidation = 0;

      this.$v.formData.$touch();

      if(this.mandatoryFields != null) {
        if(this.mandatoryFields.birth_month) { 
          this.$v.birthMonth.$touch();
          if(this.$v.birthMonth.$invalid == true) {
            nonFormDataValidation++;
          }
        }
        if(this.mandatoryFields.birth_day) { 
          this.$v.birthDay.$touch();
          if(this.$v.birthDay.$invalid == true) {
            nonFormDataValidation++;
          }
        }
        if(this.mandatoryFields.birth_year) { 
          this.$v.birthYear.$touch();
          if(this.$v.birthYear.$invalid == true) {
            nonFormDataValidation++;
          }
        }
        if(this.mandatoryFields.location_city) {
          this.$v.municity.$touch();
          if(this.$v.municity.$invalid == true) {
            nonFormDataValidation++;
          }
        }
        if(this.mandatoryFields.zipcode) {
          this.$v.zipcode.$touch();
          if(this.$v.zipcode.$invalid == true) {
            nonFormDataValidation++;
          }
        }
      }
      if (this.isZipcodeError) {
        nonFormDataValidation++;
      }

      if (!this.$v.formData.$invalid && nonFormDataValidation == 0) {
        this.formSubmitting = true;

        let params = {
          member: this.formData
        };

        if (this.requestOtpForUpdatingEmail) {
          params.otp = this.otp;
          params.otp.code = this.otpCode;
        }

        if (this.requestOtpForUpdatingMobile) {
          params.otpEmail = this.otpEmail;
          params.otpEmail.code = this.otpEmailCode;
        }

        const response = await HTTPRequest.putRequest(
          '/api/v2/clp_members/',
          params
        );

        if (response.id) {
          this.$notification.success({
            message: 'Profile has been updated.'
          });

          secureStorage.setItem('clpMemberData', response);

          this.otpRequestModal = false;
          this.otp = {};
          this.otpCode = null;
          this.requestOtpForUpdatingEmail = false;
          this.canUpdateMobile = true;
          this.canUpdateEmail = true;
        } else {
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.formSubmitting = false;
      }
    },

    /** If email is updated, this is logic for requesting OTP */
    requestOtpForEmail: async function() {
      // If mobile_no is updated, run this method.
      if (!this.otpGenerated) {
        this.formSubmitting = true;
        const response = await HTTPRequest.postRequest(
          '/api/v2/clp_otp_verifications/update_email'
        );

        if (response.member_id) {
          this.otp = response;
          this.otpRequestModal = true;
          this.otpGenerated = true;
        } else {
          this.otpGenerated = false;
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.formSubmitting = false;
      } else {
        this.otpRequestModal = true;
      }
    },

    /** If email is updated, this is logic for resending OTP */
    resendOtpForEmail: async function() {
      if (this.canResendSmsOtp) {
        this.resendingSmsOtp = true;
        const response = await HTTPRequest.postRequest(
          '/api/v2/clp_otp_verifications/update_email'
        );

        if (response.member_id) {
          this.otp = response;
          this.otpGenerated = true;

          this.resendSmsTimer = 60;
          this.canResendSmsOtp = false;
        } else {
          this.otpGenerated = false;
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.resendingSmsOtp = false;
      }
    },

    /** If mobile_no is updated, this is logic for requesting OTP */
    requestOtpForMobile: async function() {
      // If email is updated, run this method.
      if (!this.otpEmailGenerate) {
        this.formSubmitting = true;
        const response = await HTTPRequest.postRequest(
          '/api/v2/clp_otp_verifications/update_mobile'
        );

        if (response.member_id) {
          this.otpEmail = response;
          this.otpRequestModal = true;
          this.otpEmailGenerate = true;
        } else {
          this.otpEmailGenerate = false;
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.formSubmitting = false;
      } else {
        this.otpRequestModal = true;
      }
    },

    /** If mobile no is updated, this is logic for resending OTP */
    resendOtpForMobile: async function() {
      if (this.canResendEmailOtp) {
        this.resendingEmailOtp = true;
        const response = await HTTPRequest.postRequest(
          '/api/v2/clp_otp_verifications/update_mobile'
        );

        if (response.member_id) {
          this.otp = response;
          this.otpEmailGenerate = true;

          this.resendEmailTimer = 60;
          this.canResendEmailOtp = false;
        } else {
          this.otpEmailGenerate = false;
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.resendingEmailOtp = false;
      }
    },

    setFormData(data) {
      if (data != null) {
        this.getProvinces(data.region_code);
        this.getCities(data.province_code);
        this.getBarangays(data.city_code);

        const {
          membership_program_id,
          membershipProgram,
          first_name,
          middle_name,
          last_name,
          last_name_suffix,
          nick_name,
          allow_null_mobile_no,
          mobile_no,
          email,
          location_unit,
          location_street,
          location_barangay,
          barangay_code,
          location_city,
          city_code,
          location_municipality,
          municipality_code,
          location_province,
          province_code,
          location_region,
          region_code,
          location_country,
          gender,
          birth_month,
          birth_year,
          birth_day,
          dpc_consent,
          meta,
          zipcode
        } = data;

        this.getMembershipLevels(membership_program_id)
        this.formData = {
          membership_program_id,
          first_name,
          middle_name,
          last_name,
          last_name_suffix,
          nick_name,
          allow_null_mobile_no,
          mobile_no,
          email,
          location_unit,
          location_street,
          location_barangay,
          barangay_code,
          location_city,
          city_code,
          location_municipality,
          municipality_code,
          location_province,
          province_code,
          location_region,
          region_code,
          location_country,
          gender,
          birth_month,
          birth_year,
          birth_day,
          dpc_consent,
          meta,
          zipcode
        };

        this.mandatoryFields = JSON.parse(membershipProgram.mandatory_customer_fields);

        if (typeof this.memberData?.meta !== 'undefined') {
          this.memberDataMeta = this.memberData?.meta || {};
          if (
              typeof this.memberDataMeta === 'string' &&
              this.memberDataMeta !== 'null'
          ) {
            try {
              this.memberDataMeta = JSON.parse(this.memberDataMeta);
            } catch (error) {
              this.memberDataMeta = {};
            }
          }
        }
        this.birthMonth =
          birth_month != null ? moment(birth_month).format('MM') : null;
        this.birthYear =
          birth_year != null ? moment(birth_year).format('YYYY') : null;
        this.birthDay =
          birth_day != null ? moment(birth_day).format('DD') : null;

        if (mobile_no != '-1') {
          this.countryCode = mobile_no.substring(0, 3);
          this.mobileNo = mobile_no.substr(-10);
        } else {
          this.countryCode = '+63';
          this.mobileNo = mobile_no;
        }        

        (city_code) ? this.municity = city_code : this.municity = municipality_code;
      }
    },

    setFieldSettings: function() {
      const _fieldSettings = this.clpSitePageSettings.field_settings;
      this.fieldSettings = JSON.parse(_fieldSettings);
    },

    startSmsTimer: function() {
      this.smsTimer = setInterval(() => {
        this.resendSmsTimer--;

        if (this.resendSmsTimer <= 0) {
          this.resendSmsTimer = 0;
          this.canResendSmsOtp = true;
        }
      }, 1000);
    },

    startEmailTimer: function() {
      this.emailTimer = setInterval(() => {
        this.resendEmailTimer--;

        if (this.resendEmailTimer <= 0) {
          this.resendEmailTimer = 0;
          this.canResendEmailOtp = true;
        }
      }, 1000);
    },

    resetOtpTimers: function() {
      this.canResendSmsOtp = false;
      this.resendingSmsOtp = false;
      this.resendSmsTimer = 60; // in seconds
      this.smsTimer = null;
      this.canResendEmailOtp = false;
      this.resendingEmailOtp = false;
      this.resendEmailTimer = 60; // in seconds
      this.emailTimer = null;
      this.otp = {};
      this.otpEmail = {};
      this.otpEmailCode = null;
      this.otpCode = null;
    }
  },
  async created() {
    await this.getMembershipPrograms()
    await this.getRegions();
    await this.setFieldSettings();
    this.memberData = secureStorage.getItem('clpMemberData');
    await this.setFormData(this.memberData);
    await this.getDynamicFields();
  },
  watch: {

    'formData.zipcode': function (newVal, oldVal) {
      this.isZipcodeError = false;
      if (newVal != '' && newVal !== oldVal) {
        if (/^\d+$/.test(newVal) == false) {
          this.isZipcodeError = true;
        }
      }
    },
    'formData.email': function(val) {
      if (val != this.memberData.email) {
        this.requestOtpForUpdatingEmail = true;
        this.canUpdateMobile = false;
      } else {
        this.requestOtpForUpdatingEmail = false;
        this.canUpdateMobile = true;
      }
    },

    'formData.mobile_no': function(val) {
      if (val != this.memberData.mobile_no) {
        this.requestOtpForUpdatingMobile = true;
        this.canUpdateEmail = false;
      } else {
        this.requestOtpForUpdatingMobile = false;
        this.canUpdateEmail = true;
      }
    },

    birthMonth: function(month) {
      if (month != null) {
        let year = moment().year();
        let day = moment().day();
        this.formData.birth_month = moment(`${year}-${month}-${day}`).format(
          'YYYY-MM-DD'
        );
      } else {
        this.formData.birth_month = null;
      }
    },

    birthYear: function(year) {
      if (year != null) {
        let month = moment().month() + 1;
        let day = moment().day();
        this.formData.birth_year = moment(`${year}-${month}-${day}`).format(
          'YYYY-MM-DD'
        );
      } else {
        this.formData.birth_year = null;
      }
    },

    birthDay: function(day) {
      if (day != null) {
        let month = moment().month() + 1;
        let year = moment().year();
        this.formData.birth_day = moment(`${year}-${month}-${day}`).format(
          'YYYY-MM-DD'
        );
      } else {
        this.formData.birth_day = null;
      }
    },

    mobileNo: function(mobile) {
      if (mobile != null) {
        this.formData.mobile_no = this.countryCode + mobile;
      } else {
        this.formData.mobile_no = null;
      }
    }
  }
};
</script>

<style scoped></style>
